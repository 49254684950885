@font-face {
    font-family: 'Source Sans Pro';
    src: url('SourceSansPro-Bold.eot');
    src: url('SourceSansPro-Bold.eot?#iefix') format('embedded-opentype'),
        url('SourceSansPro-Bold.woff2') format('woff2'),
        url('SourceSansPro-Bold.woff') format('woff'),
        url('SourceSansPro-Bold.ttf') format('truetype'),
        url('SourceSansPro-Bold.svg#SourceSansPro-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('SourceSansPro-Regular.eot');
    src: url('SourceSansPro-Regular.eot?#iefix') format('embedded-opentype'),
        url('SourceSansPro-Regular.woff2') format('woff2'),
        url('SourceSansPro-Regular.woff') format('woff'),
        url('SourceSansPro-Regular.ttf') format('truetype'),
        url('SourceSansPro-Regular.svg#SourceSansPro-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('SourceSansPro-SemiBold.eot');
    src: url('SourceSansPro-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('SourceSansPro-SemiBold.woff2') format('woff2'),
        url('SourceSansPro-SemiBold.woff') format('woff'),
        url('SourceSansPro-SemiBold.ttf') format('truetype'),
        url('SourceSansPro-SemiBold.svg#SourceSansPro-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('SourceSansPro-SemiBoldItalic.eot');
    src: url('SourceSansPro-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('SourceSansPro-SemiBoldItalic.woff2') format('woff2'),
        url('SourceSansPro-SemiBoldItalic.woff') format('woff'),
        url('SourceSansPro-SemiBoldItalic.ttf') format('truetype'),
        url('SourceSansPro-SemiBoldItalic.svg#SourceSansPro-SemiBoldItalic') format('svg');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('SourceSansPro-LightItalic.eot');
    src: url('SourceSansPro-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('SourceSansPro-LightItalic.woff2') format('woff2'),
        url('SourceSansPro-LightItalic.woff') format('woff'),
        url('SourceSansPro-LightItalic.ttf') format('truetype'),
        url('SourceSansPro-LightItalic.svg#SourceSansPro-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('SourceSansPro-Light.eot');
    src: url('SourceSansPro-Light.eot?#iefix') format('embedded-opentype'),
        url('SourceSansPro-Light.woff2') format('woff2'),
        url('SourceSansPro-Light.woff') format('woff'),
        url('SourceSansPro-Light.ttf') format('truetype'),
        url('SourceSansPro-Light.svg#SourceSansPro-Light') format('svg');
    font-weight: 300;
    font-style: normal;
}


