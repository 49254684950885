@charset "utf-8";
@import "../fonts/stylesheet.css";

.login-page-landing{color:#2a3042;background:url(../images/login-pattern.png) repeat #20283d;font-family:'Source Sans Pro' !important;font-size:13px;line-height:none!important;-webkit-font-smoothing:subpixel-antialiased;-moz-osx-font-smoothing:subpixel-antialiased;}
 
html,body{height:100%}
.container-fluid{height:100%}
a{color:#495057;text-decoration:none}
a:hover{color:#6b717f;text-decoration:none}
strong,b{font-weight:600}
ul{margin:0;padding:0}
li{list-style:none}
img{vertical-align:middle;border-style:none}
svg{overflow:hidden;vertical-align:middle}
::-webkit-input-placeholder{color:#bcbec0!important}
::-moz-placeholder{color:#bcbec0!important}
:-ms-input-placeholder{color:#bcbec0!important}
:-moz-placeholder{color:#bcbec0!important}

.btn.focus,.btn:focus{outline:0!important;box-shadow:none!important}


.btn-default{color:#495057!important;background-color:#fff;border-color:#d3d9e6}
.btn-default:hover{color:#495057!important;background-color:#f7f9fc;border-color:#d3d9e6}
.btn-primary{color:#fff;background-color:#4466f2;border-color:#2b57e2;width:100%;padding:10px;font-size:20px;font-weight:600}
.btn-primary:hover{color:#fff;background-color:#2b57e2;border-color:#2b57e2}
.btn-red{color:#fff;background-color:#ef4136;border-color:#ef4136}
.btn-red:hover{color:#fff;background-color:#d62521;border-color:#d62521}

.main-content{position:relative; height:var(--loaderHeight)}
.navbar{padding:30px 30px 10px !important;}
.navbar-logo{display:inline-block;padding-top:0;padding-bottom:.3125rem;margin-right:1rem;font-size:1.25rem;line-height:inherit;white-space:nowrap}
.nav-fix .nav-link{height:50px}
.navbar-top{background-color:transparent}

.mrg-top{margin-top:80px}
.nav-right{color:#8898aa; font-size:16px; font-weight:600;}
.bg-gradient{background:linear-gradient(90deg,#2a3042 0,#343d56 100%)!important}
.sign-up-link{text-align:center;margin-top:15px}
.sign-up-link a{text-decoration:none;color:#8898aa;font-size:16px}
.sign-up-link a span{color:#4466f2;font-weight:600;margin-left:5px}
.sign-up-link a:hover{color:#4466f2;font-size:16px}
.copyright{text-align:center;margin-top:15px;color:#8898aa}
.login-head h2{color:#495057;text-transform:uppercase;font-weight:600;font-size:34px}
.login-head h4{color:#8898aa;font-weight:400;font-size:16px;line-height:1.5em;text-align:center}
.btn-primary{box-shadow:0 4px 6px rgba(50,50,93,.11),0 1px 3px rgba(0,0,0,.08)}
.fill-default{fill:#2a3042}
.py-8{padding-top:8rem!important;padding-bottom:8rem!important}
.header{position:relative}
.spinner-border{z-index:99999}
.divider{position:absolute;top:auto;right:0;left:0;overflow:hidden;width:100%;height:100px;transform:translateZ(0);pointer-events:none}
.divider svg{position:absolute;pointer-events:none}
.divider-bottom{top:auto;bottom:0}
.divider-bottom svg{bottom:0}
.divider-panel{height:60px}
.mt-fix{margin-top:-8rem!important}
.justify-content-center{justify-content:center!important}
[class*='shadow']{transition:all .15s ease}
.shadow{box-shadow:0 0 2rem 0 rgba(136,152,170,.15)!important}
.bg-secondary{background-color:#fff!important}
.border-0{border:0!important}

.input-group{position:relative;display:flex;width:100%;flex-wrap:wrap;align-items:stretch}
.input-group>.form-control{position:relative;width:1%;margin-bottom:0;flex:1 1 auto}
.input-group>.form-control+.form-control{margin-left:-1px}
.input-group>.form-control:focus{z-index:3}
.input-group>.form-control:not(:last-child){border-top-right-radius:0;border-bottom-right-radius:0}
.input-group>.form-control:not(:first-child){border-top-left-radius:0;border-bottom-left-radius:0}
.input-group-prepend{display:flex}
.input-group-prepend .btn{position:relative;z-index:2}
.input-group-prepend .btn+.btn,.input-group-prepend .btn+.input-group-text,.input-group-prepend .input-group-text+.input-group-text,.input-group-prepend .input-group-text+.btn{margin-left:-1px}
.input-group-prepend{margin-right:-1px}
.input-group-text{font-size:1rem;font-weight:400;line-height:1.5;display:flex;margin-bottom:0;padding:.625rem .75rem;text-align:center;white-space:nowrap;color:#7f8593;border:1px solid #cad1d7;border-radius:.375rem;background-color:#fff;align-items:center}
.input-group-text input[type='radio'],.input-group-text input[type='checkbox']{margin-top:0}
.input-group>.input-group-prepend>.btn,.input-group>.input-group-prepend>.input-group-text{border-top-right-radius:0;border-bottom-right-radius:0}
.input-group>.input-group-prepend:not(:first-child)>.btn,.input-group>.input-group-prepend:not(:first-child)>.input-group-text,.input-group>.input-group-prepend:first-child>.btn:not(:first-child),.input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child){border-top-left-radius:0;border-bottom-left-radius:0}
.input-group{transition:all .15s ease;border-radius:.375rem;box-shadow:none}
.input-group .form-control{box-shadow:none}
.input-group .form-control:not(:first-child){padding-left:0;border-left:0}
.input-group .form-control:not(:last-child){padding-right:0;border-right:0}
.input-group .form-control:focus{box-shadow:none}
.input-group-text{transition:all .2s cubic-bezier(.68,-.55,.265,1.55)}
.input-group-alternative{transition:box-shadow .15s ease;border:1px solid #ccc;box-shadow:0 1px 3px rgba(50,50,93,.15),0 1px 0 rgba(0,0,0,.02)}
.input-group-alternative .form-control,.input-group-alternative .input-group-text{border:0;box-shadow:none}

.forgot-password{text-align:center}
.forgot-password a{text-decoration:none;color:#8898aa}
.forgot-password a:hover{text-decoration:none;color:#495057}

.remember{ float:left; color:#8898aa;}

[class*=icheck-]{min-height:18px;margin-top:6px!important;margin-bottom:6px!important;padding-left:0}
.icheck-inline{display:inline-block}
.icheck-inline+.icheck-inline{margin-left:.75rem;margin-top:6px}
[class*=icheck-]>label{padding-left:29px!important;min-height:22px;line-height:22px;display:inline-block;position:relative;vertical-align:top;margin-bottom:0;font-weight:400;cursor:pointer}
[class*=icheck-]>input:first-child{position:absolute!important;opacity:0;margin:0}
[class*=icheck-]>input:first-child:disabled{cursor:default}
[class*=icheck-]>input:first-child+input[type=hidden]+label::before,[class*=icheck-]>input:first-child+label::before{content:"";display:inline-block;position:absolute;width:20px;height:20px;border:2px solid #d1d3d4;border-radius:3px;margin-left:-29px; background:#ffffff;}
[class*=icheck-]>input:first-child:checked+input[type=hidden]+label::after,[class*=icheck-]>input:first-child:checked+label::after{content:"";display:inline-block;position:absolute;top:0;left:0;width:5px;height:9px;border:2px solid #2bb673;border-left:none;border-top:none;transform:translate(7.75px,4.5px) rotate(45deg);-ms-transform:translate(7.75px,4.5px) rotate(45deg)}
[class*=icheck-]>input[type=radio]:first-child+input[type=hidden]+label::before,[class*=icheck-]>input[type=radio]:first-child+label::before{border-radius:50%}
[class*=icheck-]>input:first-child:not(:checked):not(:disabled):hover+input[type=hidden]+label::before,[class*=icheck-]>input:first-child:not(:checked):not(:disabled):hover+label::before{border-width:2px}
[class*=icheck-]>input:first-child:disabled+input[type=hidden]+label,[class*=icheck-]>input:first-child:disabled+input[type=hidden]+label::before,[class*=icheck-]>input:first-child:disabled+label,[class*=icheck-]>input:first-child:disabled+label::before{pointer-events:none;cursor:default;filter:alpha(opacity=65);-webkit-box-shadow:none;box-shadow:none;opacity:.65}
.icheck-default>input:first-child:not(:checked):not(:disabled):hover+input[type=hidden]+label::before,.icheck-default>input:first-child:not(:checked):not(:disabled):hover+label::before{border-color:#adadad}
.icheck-default>input:first-child:checked+input[type=hidden]+label::before,.icheck-default>input:first-child:checked+label::before{background-color:#e6e6e6;border-color:#adadad}
.icheck-default>input:first-child:checked+input[type=hidden]+label::after,.icheck-default>input:first-child:checked+label::after{border-bottom-color:#333;border-right-color:#333}
.icheck-success>input:first-child:not(:checked):not(:disabled):hover+input[type=hidden]+label::before,.icheck-success>input:first-child:not(:checked):not(:disabled):hover+label::before{border-color:#adadad}
.icheck-success>input:first-child:checked+input[type=hidden]+label::before,.icheck-success>input:first-child:checked+label::before{background-color:#fff;border-color:#adadad}

.error-wrap{ width:100%; display:inline-block; color: #F94839; margin-top:10px;}
.error{ border:1px solid #F94839!important; }
.error i{ color: #F94839!important; }
h1, h2, h3, h4, h5{font-family:'Source Sans Pro',Arial,Helvetica,sans-serif !important;}



.reset-password-wrap{ width:100%; display:inline-block;}
.modal-reset-password{max-width:445px; margin: 1.75rem auto; }
.modal-reset-password h1 {font-weight: 600; font-size:22px;}
.modal-reset-password .reset-info {color: #8898aa;font-weight: 400;font-size: 14px;line-height: 1.5em;}
.modal-reset-password .modal-content {background: #fff;}
.modal-reset-password .modal-header {border-bottom:none; padding: 10px 20px 10px 20px;}
.modal-reset-password .modal-footer{ padding:10px 20px;}
.modal-reset-password .modal-body {padding: 0px 30px 40px 30px;}
.modal-reset-password .btn-primary{ font-size:18px;}

.reset-password-success{width:100%; display:inline-block;}
.sent-mail-icon{ width:100%; text-align:center; display:inline-block; margin-bottom:20px;}
.sent-mail-icon img{ width:80px;}






.modal-switch-popup{max-width:1000px;margin:1.75rem auto}
.modal-switch-popup .modal-content{background:#fff}
.modal-switch-popup .modal-header{border-bottom:1px solid #dce3e6;padding:20px 30px;background-color:#fff}
.modal-switch-popup .modal-footer{padding:20px}
.modal-switch-popup .modal-body{padding:0;color:#495057}
.modal-switch-popup .modal-header .modal-title{width:100%;text-align:left;font-weight:600;font-size:22px}
.switch-popup-wraper{padding:20px 15px;margin-top:10%}
.switch-popup-wraper .col-lg-3{padding-right:10px;padding-left:10px}
.switch-popup-module{display:block;background:#fff;padding:2rem 1rem;border-radius:.5rem;box-shadow:7px 7px 30px -5px rgba(0,0,0,0.1);margin-top:10px;margin-bottom:10px;cursor:pointer;border:1px solid #dce3e6}
.switch-popup-module:hover{background:#4466f2;color:#fff;border:1px solid #fff}
.switch-popup-module:hover i{background:none;border:2px solid #fff}
.switch-popup-icowrap{width:100%;text-align:center;font-size:38px}
.switch-popup-icowrap i{background:#4466f2;border:2px solid #f1f4fa;padding:13px;border-radius:50%;color:#fff}
.switch-popup-textwrap{width:100%;text-align:center;display:inline-block;margin-top:10px}
.switch-popup-textwrap h2{font-size:18px;font-weight:600;margin-bottom:0}
.modal-backdrop.show{opacity:.7}
.modal-backdrop{background-color:#2a3042}
.switch-logo{float:left;width:100%}
.switch-logo img{float:left;margin-right:15px}
.switch-logo span{padding-left:8px;margin-left:-5px;float:left;line-height:22px;font-size:24px;margin-top:1px;text-align:left;color:#495057;font-weight:600}

.buttonpanel-fullscreen{width:100%;display:inline-block}
.buttonpanel-fullscreen .grey-btn{margin-top:0}
.popup-wrap-fullscreen{border-bottom-left-radius:6px;border-bottom-right-radius:6px;width:100%}
.fullscreenmodal-lg{margin:0!important;width:100%!important;height:100%!important;padding:0!important;min-width:100%!important}
.modal-body-fullscreen{bottom:0!important;overflow:auto!important;position:absolute!important;top:50px!important;width:100%!important;background:url(../images/login-bg.jpg) repeat #f6f9fc}
.modal-content-fullscreen{position:absolute!important;top:0!important;right:0!important;bottom:0!important;left:0!important;border:none;border-radius:0;box-shadow:none}
.modal-header-fullscreen{background:#fff;z-index:9;height:50px;left:0;padding:0;position:absolute;right:0;top:0;border-bottom:1px solid #dce3e6;-webkit-box-shadow:0 10px 5px -7px rgba(0,0,0,0.06);-moz-box-shadow:0 10px 5px -7px rgba(0,0,0,0.06);box-shadow:0 10px 5px -7px rgba(0,0,0,0.06)}
.modal-header-fullscreen h5{padding:12px 0 0 15px;font-weight:600;font-size:18px}
.close-fullscreen{background:#30394d!important;color:#fff!important;float:right!important;font-size:30px!important;font-weight:400!important;height:50px!important;line-height:1!important;margin:0!important;text-shadow:0 1px 0 #fff!important;width:50px!important;opacity:1!important}
.modal-footer-fullscreen{background:#fff;bottom:0;height:60px;left:0;padding:10px;position:absolute;right:0}














