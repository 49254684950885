.navbar-fix { padding: 0px !important }

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #0b1c52;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #0b1c52 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* small loader */

.lds-ring-sm {
  display: inline-block;
  position: relative;
  width: 22px;
  height: 22px;
  margin-right: 1rem;
}
.lds-ring-sm div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  margin: 8px;
  border: 3px solid #fff;
  border-radius: 50%;
  animation: lds-ring-sm 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring-sm div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring-sm div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring-sm div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring-sm {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.finance-license-img-wraper{width:100%;display:inline-block}
.finance-licenseboxwrap{float:left;width:48%;margin-left:10px}
.finance-licenseboxwrap:first-child{margin-left:0}
.finance-licenseboxwrap .form-text{text-align:center;font-size:12px}
.finance-licensebox{position:relative;height:100px;background:#e7ebee;border:1px dashed #a0aab4;-webkit-border-radius:10px;-moz-border-radius:10px;border-radius:10px;overflow:hidden}
.finance-licensebox img{width:100%;-webkit-border-radius:10px;-moz-border-radius:10px;border-radius:10px}
.finance-licensebox label{display:flex;align-items:center;height:100%;justify-content:center;font-size:32px;color:#a0aab4;cursor:pointer}
.finance-licensebox label:hover{color:#4466f2}
.finance-licensebox img{width:100%}
.finance-licenseboxwrap .form-text{text-align:center;font-size:12px}
.finance-licenseboxwrap{float:left;width:48%;margin-left:10px}
.finance-licenseboxwrap:first-child{margin-left:0}
.finance-licenseboxwrap .form-text{text-align:center;font-size:12px}
.finance-licensebox{position:relative;height:100px;background:#e7ebee;border:1px dashed #a0aab4;-webkit-border-radius:10px;-moz-border-radius:10px;border-radius:10px;overflow:hidden}
.finance-licensebox img{width:100%;-webkit-border-radius:10px;-moz-border-radius:10px;border-radius:10px}
.finance-licensebox label{display:flex;align-items:center;height:100%;justify-content:center;font-size:32px;color:#a0aab4;cursor:pointer}
.finance-licensebox label:hover{color:#4466f2}
.finance-licensebox img{width:100%}

label.licensebox-edit{ position:absolute; right:3px; top:3px; margin:0px; color:#495057!important;}
label.licensebox-edit i{ font-size: 11px;}

.licensebox-edit.mini-button{width:20px!important;height:20px!important;}


.secondary-license-wraper{ width:100%; display:inline-block; margin-top:10px;}
.secondary-license-img-wraper{ width:100%; display:inline-block;}


.secondary-licenseboxwrap{float:left; width:48.8%; margin-left:10px; }
.secondary-licenseboxwrap:first-child{margin-left:0px;}
.secondary-licenseboxwrap .form-text{ text-align:center; font-size:12px;}

.secondary-licensebox{ position:relative;height:140px;background:#e7ebee;border:1px dashed #a0aab4;-webkit-border-radius:10px;-moz-border-radius:10px;border-radius:10px;overflow:hidden}
.secondary-licensebox img{width:100%;-webkit-border-radius:10px;-moz-border-radius:10px;border-radius:10px}
.secondary-licensebox label{display:flex;align-items:center;height:100%;justify-content:center;font-size:32px;color:#a0aab4; cursor:pointer;}
.secondary-licensebox label:hover{color:#4466f2}
.secondary-licensebox img{width:100%}

.secondary-license-wraper h2 {
padding: 0px;
margin: 20px 0 0 0;
font-size: 18px;
font-weight: 600;
}

.editsign-btn {
position: absolute;
right: 8px;
top: 8px;
}


.secondary-license.mini-button{width:25px!important;height:25px!important;}

.modal-custom{ 
  min-width: 1000px;
}

.btn-primary-fix {
  padding: 4px 14px 5px 14px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 1.5 !important;
  border-radius: .25rem !important;
}

.publish-btn {
  width: 100px !important;
}

.save-blog-btn {
  width: 80px !important;
}
.mini-button {cursor: pointer;}
.edit-btn-fix {font-size: 13px;}
.cursor-pointer {cursor: pointer;}

.preview-modal .modal-header { background-color: #fff; padding-bottom: 1rem;}
.preview-modal .modal-body { padding: 1rem }
.preview-modal img { max-width: 100% !important; }
.preview-modal img { max-height: 1000px !important; }

.swal2-confirm { background-color: #4466f2 !important;}

.react-summer-editor .note-icon-caret::before {content: none !important;}
.react-summer-editor .note-icon-magic {margin-right: 4px;}
.react-summer-editor .note-dropdown-menu > li > a > p {padding: 0px 8px !important;}
.note-popover .popover-content .note-color .dropdown-toggle, .note-editor .note-toolbar .note-color .dropdown-toggle {height: 100%;padding-left: 2px;}
.react-summer-editor .dropdown-fontname li { padding-left: 4px;}
.react-summer-editor .dropdown-fontname li i {font-size: 18px;}
.note-modal .modal-header {flex-direction: row-reverse; background: #fff;border-bottom: 1px solid #dce3e6;padding: 10px 20px;}
.note-modal .modal-body {padding: 14px 20px 0px 20px;}
.note-modal .modal-footer {padding: 10px 20px;display: flex;justify-content: flex-end;}
.note-modal .modal-footer .btn {color: #fff;background-color: #4466f2;border-color: #2b57e2;}
.note-modal .modal-body .checkbox label {display: flex;align-items: center;
  input {margin-right: 10px;}
}

.note-modal .note-group-select-from-files {
  display: flex;
  flex-direction: column;
}

.note-modal .note-group-image-url {
  margin-top: 1rem !important;
}

.note-modal .btn-primary {
  padding: 4px 14px 5px 14px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 1.5 !important;
  border-radius: .25rem !important;
  width: 110px;
}

.note-modal .note-form-group {
  margin-bottom: .75rem;
}

.note-modal .checkbox {
  margin-bottom: .5rem;
}

.error-validate {
  border: 2px dashed red;
}

.btn-add-new-fix i{
  float: left;
  margin-right: 8px!important;
  margin-top: 1px;
}

.note-editable ol li {list-style: decimal !important;}
.note-editable ul li {list-style: disc !important;}
.note-editable li {list-style: decimal !important;}
.note-editable li {list-style: disc !important;}

.note-editable ul { padding-inline-start: 40px !important;}

.preview-modal ol li {list-style: decimal !important;}
.preview-modal ul li {list-style: disc !important;}
.preview-modal li {list-style: decimal !important;}
.preview-modal li {list-style: disc !important;}

.preview-modal ul { padding-inline-start: 40px !important;}

.modal-crop .modal-footer { display: flex;}
.modal-crop .btn-primary { width: 80px;}