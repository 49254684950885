@charset "utf-8";
/* CSS Document */

@media screen and (max-width :1800px) {
	
	.search-bar input{ width:160px!important;}	
	.nav-fix .nav-link{ font-size:13px;padding: 13px 10px !important; line-height:1.8;}
	
	}

@media screen and (max-width :1600px) {
	
	.license-item{ font-size:12px;} 
	.license-box img {width: 45px;}	
	.sales-nav-fix .nav-link .label{ display:none!important;}
	.sales-nav-fix .active-enquiry .label {display: none;}
	.sales-nav-fix .nav-link i{ padding-right:0px; font-size: 20px;}
	.sales-nav-fix .nav-link .icon-inbound{ padding-right:0px; font-size: 17px;}
	.sales-nav-fix .active-enquiry .btn i{padding-right: 5px;font-size: 16px;padding-top: 2px;float: left;}
	.sales-nav-fix .nav-link { padding: 13px 15px !important;}
	
	
	}
	
@media screen and (max-width :1500px) {	


.dealership {font-size: 10px;}
.active-enquiry {font-size: 13px;}
.dashboard-count-value{font-size: 16px;}
.activity-nav li a {padding: 8px 14px 7px;}	
.search-bar input {width: 120px!important;font-size: 13px!important;}
.navbar .ml-4{margin-left: 1.2rem !important;}

	
}
@media screen and (max-width :1450px) {
		 
	.license-box img {width: 45px;border-radius: 5px;}	
	.license-item{ font-size:12px;} 
	.nav-fix .nav-link {font-size: 12px;padding: 13px 15px !important;}
	
	a.user-profile{font-size: 12px;}
	.active-enquiry {font-size: 12px;}
	
	.email-leftbar{width: 150px;min-width: 150px;}
	.email-list-left{width: 290px;}
	.email-list-head{font-size: 13px;}
	.email-list-content{font-size: 12px;}
	.email-list-flex h6 {font-size: 12px;}
	.email-list-filter {padding: 10px;}
	.email-list-search{padding: 10px 10px;}
	.email-list-box {padding: 10px;}
	.email-menulink i {font-size: 14px;}
	.email-nav-link {font-size: 13px;}
	.compose-btn{ font-size:13px;}
	.email-datetime{ font-size:12px;}
	.email-details-username span{ font-size:12px;}
	.email-details-username {font-size: 13px;}
	.email-details-secondary-name {font-size: 13px;}
	.email-list-right {width: 220px;}
	.email-compose-wrap {padding: 10px;}
	.email-nav-link {padding: .5rem .7rem;}
	.email-details{padding: 15px;}
	.email-link-wraper{padding: 10px;}
	

	
	
	.settings-leftmenu li a{ font-size:13px;}
	.settings-leftmenu li i {font-size: 18px; padding-right: 8px;}
	.settings-leftmenu{padding: 16px 10px;}
	.settings-leftmenu h3 {padding-left: 10px;}
	.settings-leftmenu li a{padding: 10px 10px;}
	.settings-leftmenu{max-width: 200px; min-width: 200px;}
	.settings-submenu li a{font-size: 13px;}
	.settings-submenu{width: 140px;}
	.settings-submenu h4{ font-size: 14px;}
	.settings-submenu li a{padding: 10px 15px 10px 15px;}
	
	.settings-column-left {min-width: 170px;}
	.settings-column-nav li a.column-list-left{font-size: 13px;}
	
	
	
			  
	}
	
	@media screen and (max-width :1400px) {
		
	.dashboard-count-value{font-size: 16px;}
	.dashboard-module-label{ font-size:13px;}	
	.dashboard-module-count-wraper{min-height: 55px;}
	.yeargrid li a{padding: 6px 7px !important;}
	.dashboard-panel-body .dashicon-fix {padding-left: 5px!important; padding-right: 5px!important;}
	.activity-nav li a {padding: 8px 14px 7px; font-size: 13px;}	
	.add-report-column-left{width:360px}
	}
	
	
	
@media screen and (max-width :1300px) {
	
	
.nav-link .label{ display:none;}
.nav-fix .nav-link {padding: 13px 15px !important;}	
.nav-link i {font-size: 20px;padding-right: 0px;}	
.active-enquiry .label{display:none;}
.active-enquiry .btn i {padding-right: 5px;font-size: 16px;padding-top: 1px;float: left;}
	
.activity-nav li a {padding: 8px 8px 7px;font-size: 13px;}	
.enquiry-flow-nav li a {padding: 8px 6px 7px;font-size: 12px;}
.enquiry-details li{font-size: 12px;}
.enquiry-details li i {font-size: 14px;}
.table-fill td{font-size: 12px;}
.follow-up-date span{font-size: 11px;}
ul.pipeline-step li{ font-size:12px;}
ul.enquiry-overview li{ font-size:12px;}
.flow-activity-title{ font-size:14px;}
.vehicle-item-title{ font-size:13px;}
.vehicle-item-info {font-size: 12px;}
.enquiry-sidebar .card-header a{font-size: 15px;}
.enquiry-title{font-size:18px;}
.activity-nav-subinput{font-size:14px;}
.vehicle-item-owner {font-size: 12px;}
.pipeline-info{font-size: 12px;}
.pipeline-calendar{font-size: 12px;}
.pipeline-enquiry-status {margin-top: 0px;}

.license-item span {font-size: 10px;}
	
.file-manager-nav li a{padding: 5px 6px;}	
.dashboard-count-value {font-size: 14px;}
.dashboard-module-icon i {font-size: 30px;}

.email-nav-link {font-size: 12px;}
.email-menulink i {font-size: 13px;}
.email-leftbar {width: 60px;min-width: 60px;}
.email-nav-inner span{ display:none;}
.email-nav-inner span.badge.badge-pill.badge-mail{ display:block;}
.email-compose-wrap span{ display:none;}
.compose-btn i {margin-right: 0px;margin-top: 4px;}
.email-nav-inner{ width:100%; text-align:center;}
.email-menulink i {float: inherit;padding-top: 0px;margin-right: 0px;font-size: 16px;}
.email-nav-link {padding: .7rem .7rem;}
.email-compose-wrap .compose-btn i {font-size: 16px;}

.pipeline-board-header h2{font-size: 16px;}
.vehicle-item-price{font-size: 12px;}
.yeargrid li a{padding: 6px 5px !important;}


	
	}
	
@media screen and (max-width :1200px) {
		
.activity-nav li a {padding: 8px 9px 7px;font-size: 12px;}
.dashboard-panel-body .col-lg-3 {padding-left: 2px;padding-right: 2px;}
.dashboard-panel-body .dashicon-fix {padding-left: 2px!important;padding-right: 2px!important;}
.notification-icon.ml-4 {margin-left:18px!important;}
.chat-icon.ml-4 {margin-left:18px !important;}
.nav-item.ml-3.profile.dropdown {margin-left: 10px !important;}
.nav-fix .nav-link {padding: 13px 12px !important;}
.user-profile .user-name {display: none;}
.dashboard-box-header h3 {font-size: 16px;}
.dashboard-panel-head h3{font-size: 18px;}
.dashboard-box-header::before{height: 23px;bottom: 17px;}
.dashboard-panel-head::before{bottom: 17px;}
.enquirydetail-grid .small-column {min-width: 250px;}

.license-item td {width: 100%;display: inline-block ; border-bottom: 1px solid #dee2e6 !important; padding-bottom: 4px;}
.license-item .border-left.pl-3 {padding-left: 0px !important;border-left: none !important;}
.license-item .license-box {float: left;margin-left: 0px;border: 1px solid #fff;border-radius: 5px;padding-top: 5px;margin-right: 5px;}

.vehicle-item-image {display: none;}
.testdrive-duration .label-duration{font-size: 11px;}
.yeargrid li a {padding: 7px 3px !important;font-size: 10px;}
.yeargrid li {margin-left: 3px;}
.activity-nav li a {padding: 8px 8px 7px;font-size: 12px;}
		
		}
@media screen and (max-width :1024px) {
	
	
// body{ min-width: 1024px;

// overflow: hidden;
// position: absolute;
// left: 0;
// right: 0;
// bottom: 0;
// top: 0;

// }

.fixed-top {width: 1024px;}	
	
	}	
	
	
	
	

